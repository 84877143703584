import React, { useEffect, useState } from 'react';
import '../assets/css/material-dashboard.css'
import HomeLf from './HomeLf';
import { db, firebaseAuth } from '../utils/firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import Background from 'three/examples/jsm/renderers/common/Background.js';
import { Center } from '@react-three/drei';
import axios from 'axios';

function Colaborador(params) {

  const navigate = useNavigate();


  const [current, setCurrent] = useState('');
  const [dataA, setDataA] = useState([]);
  const [filtrados, setFiltrados] = useState([]);
  const [isAdm, setIsAdm] = useState(false);
  const [isNot, setIsNot] = useState(false);
  
  const [notif, setNotif] = useState([]);
  const [hover01, setHover01] = useState(false);

var count01 = 0;


async function master(params) {

      

  var objMaster = [];
  var objMaster2 = [];
  var disciplinaColaborador = '';
  


  onAuthStateChanged(firebaseAuth, async (currentUser) => {
    if (currentUser) {
      var usuarioLogado = currentUser.email;
      setCurrent(usuarioLogado);



  
   await getDocs(collection(db, "projetos"))
            .then((querySnapshot)=>{               
                const newData = querySnapshot.docs
                    .map((doc) => ({...doc.data(), id:doc.id }));

                   // console.log('MASTER MASTER', newData[0].emailsParticipantes);
                   var ultimate = []
                    for (let i = 0; i < newData.length; i++) {    
                                       if(newData[i].emailsParticipantes != undefined){
                                        if(newData[i].emailsParticipantes.includes(usuarioLogado)){
                                          objMaster.push(newData[i]);
                                          objMaster2.push(newData[i]);
                                        }
                    }}
         

     
                    for (let i = 0; i < objMaster.length; i++) { objMaster[i].isadmm = false; }

                    for (let i = 0; i < objMaster.length; i++) {                     
                          if(objMaster[i].colaboradores != undefined){

                         
                      const subArr = objMaster[i].colaboradores.filter(str => str.includes(usuarioLogado));
                      
                      if(subArr[0].split('###master')[2].replaceAll(' ', '') != 'Prestadordeserviços'){
                       objMaster[i].isadmm = true;
                      }                
  }
}
















    var trademaster = [];
    var objtrade = [];
    var tradetrade = [];




    for (let i = 0; i < objMaster.length; i++) {
        tradetrade.push(Object.values(objMaster[i].ordens));
    }

    objtrade.push(tradetrade);
    
  
        for (let i = 0; i < objtrade.length; i++) {       
           objtrade[i].forEach((element) =>  trademaster.push(element));
           
        }
        console.log('trademaster', trademaster);
        var tradeplus = [];
        for (let i = 0; i < trademaster.length; i++) {
          if(trademaster[i].length > 0){
            trademaster[i].forEach((element) =>  tradeplus.push(element));

           
          }
          
        }
     


        var tradeultra = tradeplus[0];

       // console.log('tradeplus', tradeplus[1].length);

        


     
            function filterFilter(params) {
              var filtradosMaster = objMaster2.find((resultados)=> {
                return resultados.idProjeto == params;
               })
          
               var refUsr = usuarioLogado;
                
               var call = filtradosMaster.colaboradores.filter(str => str.includes(refUsr));
               var callMaster = call[0].split('###master')[1].replaceAll(' ', '');
               return callMaster;
            }



            var notificacoesArray = [];
       
      for (let i = 0; i < tradeplus.length; i++) {
              if(tradeplus[i].disciplinas){

           
           if(tradeplus[i].disciplinas.includes(filterFilter(tradeplus[i].idDoProjeto))){
            notificacoesArray.push(tradeplus[i]);
           }   }
        
      }
       
     //   console.log('notificacoesArray', notificacoesArray)


                    setNotif(notificacoesArray);

                    setFiltrados(objMaster);
                  
            })
        
         




    };
  });


 



}









function fetchMaster(params) {
  




     const options = {
      url: 'https://lumera-api.vercel.app/tokenviewer',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      }
   
    };
    axios(options)
      .then(response => {
    //    setTokenT(response.data.access_token);
     //   console.log(response.data.access_token);
     window.location.href = `/projeto?urn=${params}&token=${response.data.access_token}`;
      });
  


}



useEffect(()=> {
  master();
},[])

















    return (
        <div>


<HomeLf/>



  <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

    <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" data-scroll="true">
      <div class="container-fluid py-1 px-3">
     
    
      </div>
    </nav>

    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <div class="card"
            onMouseLeave={()=> setHover01(false)}
>
            <div class="card-header p-3 pt-2">
            
              <div class="text-end pt-1">
                <p class="text-sm mb-0 text-capitalize">Notificações</p>
                <h4 class="mb-0">{notif.length > 0 ? <a style={{color: 'rgba(200,100,100,0.7)'}}
                onMouseOver={()=> setHover01(true)}
                >Pendência</a> : '0'}</h4>
                <div style={{display: hover01 ? 'block' : 'none'}}>
                  {

                    notif.map((data)=> {
                  //    console.log(data);
                       return <><a style={{textDecoration: 'underline', color: data.prioridade == 'alta' ? 'red' : 'black', cursor: 'pointer'}}
                          onClick={ ()=> navigate(`/ordem?prjid=${data.idDoProjeto}&ordem=${data.nome}`)}
                       >{data.nome}</a> <br/> </> 
                    })
                  }

                </div>
              </div>
            </div>
            <hr class="dark horizontal my-0"/>
            <div class="card-footer p-3">

            </div>
          </div>
        </div>

      </div>
      
      <div class="row mb-4"  style={{marginTop: '20px'}}>
        <div class="col-lg-12 col-md-6 mb-md-0 mb-4">
          <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-lg-6 col-7">
                  <h6 style={{color: 'black'}}>Projeto</h6>
                  <p class="text-sm mb-0">
                 
            
                  </p>
                </div>
                <div class="col-lg-6 col-5 my-auto text-end">
               
                </div>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive">
     
     




              <>
                                  {filtrados.map((data, index) => {

return <> 

{data.nomeDoProjeto.length > 1 ? 

<div style={{width: '100%', height: '280px', padding: '30px 20px', border: 'solid 2px', borderColor: 'rgba(150,150,150,0.2)', borderRadius: '10px', marginBottom: '10px'}}>
  <h3>{data.nomeDoProjeto}</h3>
  <div style={{ margin: '10px'}}>
    <a style={{fontWeight: '800'}}>Descrição:</a> {data.comentariosIniciais}
  </div>







  <div style={{ margin: '10px'}}>
  <a style={{fontWeight: '800'}}>Disciplinas:</a>
  <>
                                  {data.disciplinas.map((data2, index) => {
return <> 

  <a>  {data2},</a>
</>
})}

                    </>
  </div>
  <div style={{display: 'flex', marginTop: '50px'}}>
    {data.urn.length > 2 ? 
  <button style={{ margin: '10px', border: 'solid black 2px', borderRadius: '10px', padding: '10px', display: 'flex', alignContent: 'center', alignItems: 'center'}}
  onClick={()=> fetchMaster(data.urn)}
  >
 CadViewer </button>
  : 
<>
{ data.isadmm &&
  <button style={{ margin: '10px', border: 'solid black 2px', borderRadius: '10px', padding: '10px', display: 'flex', alignContent: 'center', alignItems: 'center'}}
  onClick={()=> navigate(`/uploadmaster?idproject=${data.idProjeto}`)}
  >
  Upload 3d </button>}
  </>
  }
         
      
                      { data.isadmm &&
                    <button style={{ margin: '10px', border: 'solid black 2px', borderRadius: '10px', padding: '10px', display: 'flex', alignContent: 'center', alignItems: 'center'}}
                    onClick={()=> navigate(`/criarordem?prjid=${data.idProjeto}`)}
                    >
                   Criar pendência </button>         
                  }

                    <button style={{ margin: '10px', border: 'solid black 2px', borderRadius: '10px', padding: '10px', display: 'flex', alignContent: 'center', alignItems: 'center'}}
                        onClick={()=> navigate(`/ordens?prjid=${data.idProjeto}`)}
                    >
                   Pendências </button>         

                    { data.isadmm &&
                    <button style={{ margin: '10px', border: 'solid black 2px', borderRadius: '10px', padding: '10px', display: 'flex', alignContent: 'center', alignItems: 'center'}}
                    onClick={()=> navigate(`/editarprojeto?prjid=${data.idProjeto}`)}
                    >
                Editar </button> 
                                  }
                    { data.isadmm &&
                    <button style={{ margin: '10px', border: 'solid black 2px', borderRadius: '10px', padding: '10px', display: 'flex', alignContent: 'center', alignItems: 'center'}}
                        onClick={()=> navigate(`/adicionarcolaborador?prjid=${data.idProjeto}`)}
                    >
               Adicionar Colaborador </button>  
                                  }
</div>

</div>

                : ''
}





</>
})}
                    </>

       


              </div>
            </div>
          </div>
        </div>
   
      </div>
      <footer class="footer py-4  ">
        <div class="container-fluid">
          <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-6 mb-lg-0 mb-4">
  
            </div>
     
          </div>
        </div>
      </footer>
    </div>
  </main>






        </div>
    )
}
export default Colaborador;
