


import { Navigate, useNavigate } from 'react-router-dom';
import { doc, setDoc, updateDoc } from 'firebase/firestore';
import { db, firebaseAuth } from './utils/firebase-config';
import { useEffect, useState } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';

import logo from "./assets/version2/logo.png";
import group1 from "./assets/version2/group1.png";
import group2 from "./assets/version2/group2.png";
import group3 from "./assets/version2/group3.png";
 


function Home() {
    const navigate = useNavigate();





    const [logado, setLogado] = useState(false);




    function master(params) {
        

    onAuthStateChanged(firebaseAuth, async (currentUser) => {
        if (currentUser) {setLogado(true);
        
     
    }
      });
    }

      function logOut(params) {
        signOut(firebaseAuth); 
        setLogado(false);
      }


useEffect(()=> {
master();

}, []);





async function testRegister() {
    await updateDoc(doc(db, 'clientes', `${'althertechadm@gmail.com'}`), {

        nome: {maria: 222, joão: 333},
        item01: {nome: 'item01', descricao: 'item01 01 01'},
        item02: {nome: 'item01', descricao: 'item01 01 01'},
        item03: {nome: 'MASTER', descricao: 'MASTER'}
     
    
    
      });
}

if(window.location.href.slice(window.location.href.length - 1, window.location.href.length) != '/') {
    document.querySelector('.oisumida').style.display = 'none';
} else {

    document.querySelector('.oisumida').style.display = 'block';
    
}


  return (
    <div className="App">
      
      {
        // APP MASTER
      }

      
<header id="site-header" class="main-header main-header-overlay" data-sticky-header="true"
>
    <section class="lqd-section desktop-header md:hidden">
        <div class="container-fluid p-0">
            <div class="flex flex-wrap w-full justify-between">
                <div class="navbar-brand-plain w-15percent py-30 flex"><a  class="navbar-brand flex relative p-0 leading-1/4em"><span class="navbar-brand-inner post-rel"><img class="logo-default" src={logo} alt="Lumera" /></span></a></div>
                <div
                class="module-primary-nav w-60percent flex justify-end lg:w-85percent">
                    <div class="navbar-collapse inline-flex lqd-submenu-cover p-0" id="main-header-collapse" aria-expanded="false" role="navigation">
                        <ul id="primary-nav" class="main-nav flex justify-center reset-ul inline-ul lqd-menu-counter-right lqd-menu-items-inline main-nav-hover-default lqd-submenu-toggle-hover"
                        data-localscroll="true" data-localscroll-options="{&quot;itemsSelector&quot; : &quot;>li > a&quot;, &quot;trackWindowScroll&quot;: true, &quot;includeParentAsOffset&quot;: true}">
                            <li class="is-active"><a class="leading-1/4em text-blue-700" 
                            onClick={()=> {navigate('/main'); document.querySelector('section').style.display = 'none'}}
                            style={{color: '#985EAC'}}
                            >Home</a></li>
                            <li class="menu-item-has-children"><a class="leading-1/4em text-blue-700" onClick={()=> navigate('/sobre')}>Sobre <span class="submenu-expander"></span> <span class="link-icon inline-flex hide-if-empty right-icon"></span></a></li>
                            <li
                            class="menu-item-has-children"><a class="leading-1/4em text-blue-700">Como usar <span class="submenu-expander"></span> </a></li>
                                <li><a class="leading-1/4em text-blue-700" >Contato</a></li>
                           
                        </ul>
                    </div>
            </div>
            <div class="w-25percent flex items-center justify-end lg:hidden">
                <div class="widget-text-editor mr-15">
                    <a class="leading-1/4em text-blue-700" 
                    onClick={()=> navigate('/login')}
                    style={{cursor: 'pointer'}}
                    >Login <span class="submenu-expander"></span> <span class="link-icon inline-flex hide-if-empty right-icon"></span></a></div>
                <div class="module-btn px-0/5em">
                    <a class="leading-1/4em text-blue-700" 
                     onClick={()=> navigate('/register')}
                     style={{cursor: 'pointer'}}

                    >Cadastrar-se <span class="submenu-expander"></span> <span class="link-icon inline-flex hide-if-empty right-icon"></span></a>
                </div>
            </div>
        </div>
        </div>
    </section>
    <div class="lqd-mobile-sec relative">
        <div class="lqd-mobile-sec-inner navbar-header flex items-stretch w-full">
            <div class="lqd-mobile-modules-container empty"></div>
            <button type="button" class="bg-transparent border-none navbar-toggle collapsed nav-trigger style-mobile flex relative items-center justify-end p-0" data-ld-toggle="true" data-bs-toggle="collapse" data-bs-target="#lqd-mobile-sec-nav"
            aria-expanded="false" data-toggle-options="{ &quot;changeClassnames&quot;:  {&quot;html&quot;:  &quot;mobile-nav-activated&quot;} }"><span class="sr-only">Menu</span> <span class="bars inline-block relative z-1"><span class="bars-inner flex flex-col w-full h-full"><span class="bar inline-block"></span> <span class="bar inline-block"></span> <span class="bar inline-block"></span></span>
                </span>
            </button> <a class="navbar-brand flex relative leading-1/4em"><span class="navbar-brand-inner"><img class="logo-default" src="./assets/images/demo/digital-hub/logo.svg" alt="Hub" /></span></a></div>
        <div class="lqd-mobile-sec-nav w-full absolute z-10">
            <div class="mobile-navbar-collapse navbar-collapse collapse w-full" id="lqd-mobile-sec-nav" aria-expanded="false" role="navigation">
                <ul id="mobile-primary-nav" class="reset-ul lqd-mobile-main-nav main-nav lqd-submenu-toggle-hover">
                    <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-7550 current_page_item menu-item-7601"><a href="#banner" class="leading-1/4em">Home</a></li>
                    <li class="menu-item-has-children"><a  class="leading-1/4em">Case Studies <span class="submenu-expander absolute"></span> <span class="link-icon inline-flex hide-if-empty right-icon"><i class="lqd-icn-ess icon-ion-ios-arrow-down"></i></span></a></li>
                    <li
                    class="menu-item-has-children"><a  class="leading-1/4em">Services <span class="submenu-expander absolute"></span> <span class="link-icon inline-flex hide-if-empty right-icon"><i class="lqd-icn-ess icon-ion-ios-arrow-down"></i></span></a></li>
                        <li><a  class="leading-1/4em">Testimonial</a></li>
                        <li><a  class="leading-1/4em">Blog</a></li>
                        <li><a  class="leading-1/4em">Contact Us</a></li>
                </ul>
            </div>
        </div>
    </div>
</header>


    <div id="wrap" style={{marginTop: '50px'}}>
        <div class="lqd-sticky-placeholder hidden"></div>
        <header id="site-header" class="main-header main-header-overlay" data-sticky-header="true" data-sticky-values-measured="true" data-sticky-options="{&quot;disableOnMobile&quot;: true}">
        

    </header>
    <main class="content bg-cover bg-white" id="lqd-site-content" 
                                                            style={{backgroundImage: "url(./assets/images/demo/digital-hub/bg-min.png)"}}>
        <div id="lqd-contents-wrap">
    
    






























    

        <section class="lqd-section services pt-50 ca-initvalues-applied lqd-animations-done" 
        id="services" data-custom-animations="true" 
        data-ca-options="{&quot;animationTarget&quot;: &quot;.animation-element&quot;,
             &quot;ease&quot;: &quot;power4.out&quot;,
              &quot;initValues&quot;: {&quot;y&quot;: &quot;60px&quot;, &quot;rotationZ&quot; : 5,
                 &quot;opacity&quot; : 0, &quot;transformOrigin&quot;: &quot;50% 50% 120px&quot;} , &quot;animations&quot;: {&quot;y&quot;: &quot;0px&quot;, &quot;rotationZ&quot; : 0, &quot;opacity&quot; : 1, &quot;transformOrigin&quot;: &quot;50% 50% 0px&quot;}}">
            <div class="container">
                <div class="row">
                    <div class="col col-xl-4 col-12 mb-30 sm:py-0">
                        <div class="iconbox flex flex-grow-1 relative items-center iconbox-custombg animation-element lqd-unit-animation-done" >
                            <div class="iconbox-icon-wrap mr-60">
                                <div class="iconbox-icon-container relative z-1 m-0 w-55 h-55 text-primary text-54">
                                    <span class="icon-custom-bg">
                                        <svg class="w-145" viewBox="0 0 200 200">
                                            <path fill="#F2F4F8" d="M54.4, -27.6C66, -11.2, 68, 14.6, 57.3, 31.9C46.6, 49.1, 23.3, 57.8, -0.3, 58C-23.9, 58.2, -47.9, 49.8, -55.4, 34.4C-63, 18.9, -54.2, -3.6, -42.2, -20.3C-30.3, -36.9, -15.1, -47.6, 3.1, -49.3C21.3, -51.1, 42.7, -44.1, 54.4, -27.6Z" transform="translate(100 100)"></path>
                                        </svg>
                                    </span>
                              <img src={group1} />
                                </div>
                            </div>
                            <div class="contents">
                                <h3 class="lqd-iconbox-heading mb-0/7em text-18">Controle Total da Equipe</h3>
                                <p>Organize sua equipe de forma eficiente. Defina permissões, atribua funções e facilite a colaboração no gerenciamento de seus projetos 3D.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col col-xl-4 col-12 mb-30 sm:py-0">
                        <div class="iconbox flex flex-grow-1 relative items-center iconbox-custombg animation-element lqd-unit-animation-done" >
                            <div class="iconbox-icon-wrap mr-60">
                                <div class="iconbox-icon-container relative z-1 m-0 w-55 h-55 text-primary text-54">
                                    <span class="icon-custom-bg">
                                        <svg class="w-145" viewBox="0 0 200 200">
                                            <path fill="#F2F4F8" d="M54.4, -27.6C66, -11.2, 68, 14.6, 57.3, 31.9C46.6, 49.1, 23.3, 57.8, -0.3, 58C-23.9, 58.2, -47.9, 49.8, -55.4, 34.4C-63, 18.9, -54.2, -3.6, -42.2, -20.3C-30.3, -36.9, -15.1, -47.6, 3.1, -49.3C21.3, -51.1, 42.7, -44.1, 54.4, -27.6Z" transform="translate(100 100)"></path>
                                        </svg>
                                    </span>
                                    <img src={group2} />
                                </div>
                            </div>
                            <div class="contents">
                                <h3 class="lqd-iconbox-heading mb-0/7em text-18">Gerenciamento de Modelos 3D</h3>
                                <p>Envie e organize seus modelos 3D diretamente na plataforma. Mantenha todos os arquivos atualizados e acessíveis para sua equipe, sem complicações.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col col-xl-4 col-12 mb-30 sm:py-0">
                        <div class="iconbox flex flex-grow-1 relative items-center iconbox-custombg animation-element lqd-unit-animation-done" >
                            <div class="iconbox-icon-wrap mr-60">
                                <div class="iconbox-icon-container relative z-1 m-0 w-55 h-55 text-primary text-54">
                                    <span class="icon-custom-bg">
                                        <svg class="w-145" viewBox="0 0 200 200">
                                            <path fill="#F2F4F8" d="M54.4, -27.6C66, -11.2, 68, 14.6, 57.3, 31.9C46.6, 49.1, 23.3, 57.8, -0.3, 58C-23.9, 58.2, -47.9, 49.8, -55.4, 34.4C-63, 18.9, -54.2, -3.6, -42.2, -20.3C-30.3, -36.9, -15.1, -47.6, 3.1, -49.3C21.3, -51.1, 42.7, -44.1, 54.4, -27.6Z" transform="translate(100 100)"></path>
                                        </svg>
                                    </span>
                                    <img src={group3} />
                                </div>
                            </div>
                            <div class="contents">
                                <h3 class="lqd-iconbox-heading mb-0/7em text-18">Gestão de Pendências</h3>
                                <p>Diga adeus ao caus dos e-mails! Com o Lumera 3D, acompanhe pendencias do projeto em um só lugar. Centralize informações, delegue tarefas e mantenha sua equipe sempre atualizada.
                                            
                                        </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>














        <section class="lqd-section footer-menu pt-50 pb-20">
            <div class="container">
                <div class="row">
                    <div class="w-45percent flex flex-col justify-start p-0 lg:w-full">
                        <div class="flex flex-col py-10 pr-90 pl-10 mb-30 module-content">
                            <div class="lqd-imggrp-single block relative mb-35">
                            
                                </div>
                                <div class="ld-fancy-heading relative">
                                 
                                 
                                 <img src={logo} style={{width: '300px'}}></img>
                                </div>
                            
                            </div>
                        </div>
                        <div class="w-55percent flex p-0 lg:w-full">
                            <div class="w-full flex flex-wrap p-10 mb-30">
                                <div class="w-33percent sm:w-50percent">
                                    <div class="module-list flex flex-col justify-start mb-30 p-10">
                                        <div class="ld-fancy-heading relative" style={{display: 'flex'}}>
                                            <h3 class="ld-fh-element inline-block relative mb-1/35em text-16 uppercase tracking-1">Lumera 3D</h3>
                                        </div>
                                        <div class="lqd-fancy-menu lqd-custom-menu relative lqd-menu-td-none">
                                            <ul class="reset-ul">
                                                <li class="w-full flex items-center mb-10">
                                                    <a class="w-full flex items-center leading-1/4em text-blue-400" >Sobre nós</a>
                                                </li>
                                                <li class="w-full flex items-center mb-10">
                                                    <a class="w-full flex items-center leading-1/4em text-blue-400" >Como usar</a>
                                                </li>
                                                <li class="w-full flex items-center mb-10">
                                                    <a class="w-full flex items-center leading-1/4em text-blue-400" >Acessar meus Projetos</a>
                                                </li>
                                            
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-33percent sm:w-50percent">
                                    <div class="module-list flex flex-col justify-start mb-30 p-10">
                                        <div class="ld-fancy-heading relative" style={{display: 'flex'}}>
                                            <h3 class="ld-fh-element inline-block relative mb-1/35em text-16 uppercase tracking-1">Legal</h3>
                                        </div>
                                        <div class="lqd-fancy-menu lqd-custom-menu relative lqd-menu-td-none">
                                            <ul class="reset-ul">
                                                <li class="w-full flex items-center mb-10">
                                                    <a class="w-full flex items-center leading-1/4em text-blue-400" >Termos de Uso</a>
                                                </li>
                                                <li class="w-full flex items-center mb-10">
                                                    <a class="w-full flex items-center leading-1/4em text-blue-400" >Política de Privacidade</a>
                                                </li>
                                           
                                        
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-33percent sm:w-50percent">
                                    <div class="module-list flex flex-col justify-start mb-30 p-10">
                                        <div class="ld-fancy-heading relative" style={{display: 'flex'}}>
                                            <h3 class="ld-fh-element inline-block relative mb-1/35em text-16 uppercase tracking-1">Entre em contato</h3>
                                        </div>
                                        <div class="lqd-fancy-menu lqd-custom-menu relative lqd-menu-td-none">
                                            <ul class="reset-ul">
                                                <li class="w-full flex items-center mb-10">
                                                    <a class="w-full flex items-center leading-1/4em text-blue-400">Enviar mensagem</a>
                                                </li>
                                                <li class="w-full flex items-center mb-10">
                                                    <a class="w-full flex items-center leading-1/4em text-blue-400">contato@lumera3d.com</a>
                                                </li>
                                             
                                    
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
              






              
            </section>



            <div 
style={{width: '100%', backgroundColor: '#36363642', height: '30px', fontFamily: 'Inter, serif',
    fontSize: '11px', color: '#323743', display: 'flex', justifyContent: 'center',
    alignItems: 'center', 
}}
>
    © 2025 Lumera 3D - Uma marca Kollabity Engineering Data
</div>







        
        </div>
        
    </main>
 

    </div>







    </div>
  );
}

export default Home;
