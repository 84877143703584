import React, { useEffect, useState } from 'react';
import '../assets/css/material-dashboard.css'
import HomeLf from './HomeLf';
import { db, firebaseAuth } from '../utils/firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import Background from 'three/examples/jsm/renderers/common/Background.js';
import { Center } from '@react-three/drei';

function OrdensDeServico(params) {

  const navigate = useNavigate();


  const [current, setCurrent] = useState('');
  const [dataA, setDataA] = useState([]);
  const [filtrados, setFiltrados] = useState([]);
  const [filtradosB, setFiltradosB] = useState([]);
  const [objetoTarget, setObjetoTarget] = useState();
  const [dataId, setDataId] = useState();


var count01 = 0;
const queryParameters = new URLSearchParams(window.location.search)
const parametroId = queryParameters.get("prjid")

async function master(params) {

      

  var objMaster = [];
  var objMaster2 = [];

   


  onAuthStateChanged(firebaseAuth, async (currentUser) => {
    if (currentUser) {
      var usuarioLogado = currentUser.email;
      setCurrent(usuarioLogado);
    };
  });


  await fetch('https://lumera-api.vercel.app/consultaOrdem', {
    method: 'POST',
    body: JSON.stringify({
      parametroId: parametroId,

    }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
     .then((response) => response.json())
     .then((data) => {
        console.log(data);

        var criados = data;
        var tradd = [];
        setFiltradosB(criados);


        tradd.push(criados.ordens);

        tradd = tradd[0];
        console.log('SSSSSS ',tradd);
        var masterTrad = [];
      for (let i = 0; i < Object.keys(criados.ordens).length; i++) {
            console.log(tradd[Object.keys(criados.ordens)[i]]);
            masterTrad.push(tradd[Object.keys(criados.ordens)[i]]);
      }
            var filtrar = [];
        for (let i = 0; i < masterTrad.length; i++) {
          if(masterTrad[i].status == 'Pendente'){
            filtrar.push(masterTrad[i]);
          }
          
        }
        console.log('MASTER MASTER', masterTrad);
      setFiltrados(filtrar);
              











     })
     .catch((err) => {
        console.log(err.message);
     });



        



}


async function editar(params) {
  navigate(`/editar?projeto=${params}`);
}





async function dell(params) {


  await updateDoc(doc(db, 'clientes', `${firebaseAuth.currentUser.email}:projetos`), {
    [params]: {
        nomeDoProjeto: 'nd',
      

    }
  
  });
  navigate(0);
}






useEffect(()=> {
  master();
},[])

















    return (
        <div>


<HomeLf/>



<div
  className="col-12"
  style={{
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    marginTop: 50,
    paddingRight: 20
  }}
>
  <div className="col-3" style={{ padding: "0px 20px" }}>
    <div
      style={{
        border: "1px solid #DEE1E6",
        height: "100%",
        width: "100%",
        borderRadius: 10
      }}
    >
      <div
        style={{
          padding: "30px 0px",
          display: "flex",
          justifyContent: "center",
          borderBottom: "1px solid #F3F4F6",
          margin: "0px 10px"
        }}
      >
        <div
          style={{
            width: 154,
            height: 36,
            backgroundColor: "#574A69",
            borderRadius: 10,
            fontFamily: "inter, serif",
            fontSize: 14,
            color: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: 'pointer'
          }}
          onClick={()=> navigate(`/criarordem?prjid=${parametroId}`)}
        >
          <a style={{ fontSize: 25, fontWeight: 200, marginRight: 4 }}>+ </a>{" "}
          Nova Pendência
        </div>
      </div>
      <div
        style={{
          padding: "30px 10px",
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid #F3F4F6",
          margin: "0px 10px",
          fontFamily: "Archivo, serif",
          fontWeight: 500,
          fontSize: 16,
          color: "#171A1F"
        }}
      >
        Filtros{" "}
        <a style={{ fontSize: 14, fontWeight: 400, color: "#574A69" }}>
          Limpar Tudo
        </a>
      </div>
      <div
        style={{
          padding: "30px 10px",
          display: "flex",
          flexFlow: "column",
          justifyContent: "space-between",
          borderBottom: "1px solid #F3F4F6",
          margin: "0px 10px",
          fontFamily: "Archivo, serif",
          fontWeight: 500,
          fontSize: 16,
          color: "#171A1F"
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <a>Status</a>{" "}
          <a style={{ fontSize: 14, fontWeight: 400, color: "#574A69" }}>
            <i style={{ color: "black" }} className="bi bi-chevron-down" />
          </a>
        </div>
        <div
          style={{
            marginTop: 15,
            fontFamily: "Inter, serif",
            fontSize: 14,
            fontWeight: 400,
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center"
          }}
        >
          <input
            style={{ width: 14, height: 14, marginRight: 5 }}
            type="checkbox"
          />{" "}
          <a>Pendente</a>
        </div>
        <div
          style={{
            marginTop: 5,
            fontFamily: "Inter, serif",
            fontSize: 14,
            fontWeight: 400,
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center"
          }}
        >
          <input
            style={{ width: 14, height: 14, marginRight: 5 }}
            type="checkbox"
          />{" "}
          <a>Concluído</a>
        </div>
      </div>
      <div
        style={{
          padding: "30px 10px",
          display: "flex",
          flexFlow: "column",
          justifyContent: "space-between",
          borderBottom: "1px solid #F3F4F6",
          margin: "0px 10px",
          fontFamily: "Archivo, serif",
          fontWeight: 500,
          fontSize: 16,
          color: "#171A1F"
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <a>Responsável pela Solução</a>{" "}
          <a style={{ fontSize: 14, fontWeight: 400, color: "#574A69" }}>
            <i style={{ color: "black" }} className="bi bi-chevron-down" />
          </a>
        </div>
        <div
          style={{
            marginTop: 15,
            fontFamily: "Inter, serif",
            fontSize: 14,
            fontWeight: 400,
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center"
          }}
        >
          <input
            style={{ width: 14, height: 14, marginRight: 5 }}
            type="checkbox"
          />{" "}
          <a>Mecânica</a>
        </div>
        <div
          style={{
            marginTop: 5,
            fontFamily: "Inter, serif",
            fontSize: 14,
            fontWeight: 400,
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center"
          }}
        >
          <input
            style={{ width: 14, height: 14, marginRight: 5 }}
            type="checkbox"
          />{" "}
          <a>Civil</a>
        </div>
        <div
          style={{
            marginTop: 5,
            fontFamily: "Inter, serif",
            fontSize: 14,
            fontWeight: 400,
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center"
          }}
        >
          <input
            style={{ width: 14, height: 14, marginRight: 5 }}
            type="checkbox"
          />{" "}
          <a>Elétrica</a>
        </div>
      </div>
      <div
        style={{
          padding: "30px 10px",
          display: "flex",
          flexFlow: "column",
          justifyContent: "space-between",
          borderBottom: "1px solid #F3F4F6",
          margin: "0px 10px",
          fontFamily: "Archivo, serif",
          fontWeight: 500,
          fontSize: 16,
          color: "#171A1F"
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <a>Requisitante</a>{" "}
          <a style={{ fontSize: 14, fontWeight: 400, color: "#574A69" }}>
            <i style={{ color: "black" }} className="bi bi-chevron-down" />
          </a>
        </div>
        <div
          style={{
            marginTop: 15,
            fontFamily: "Inter, serif",
            fontSize: 14,
            fontWeight: 400,
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center"
          }}
        >
          <input
            style={{ width: 14, height: 14, marginRight: 5 }}
            type="checkbox"
          />{" "}
          <a>Tubulação</a>
        </div>
        <div
          style={{
            marginTop: 5,
            fontFamily: "Inter, serif",
            fontSize: 14,
            fontWeight: 400,
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center"
          }}
        >
          <input
            style={{ width: 14, height: 14, marginRight: 5 }}
            type="checkbox"
          />{" "}
          <a>Civil</a>
        </div>
        <div
          style={{
            marginTop: 5,
            fontFamily: "Inter, serif",
            fontSize: 14,
            fontWeight: 400,
            display: "flex",
            flexFlow: "row nowrap",
            alignItems: "center"
          }}
        >
          <input
            style={{ width: 14, height: 14, marginRight: 5 }}
            type="checkbox"
          />{" "}
          <a>Elétrica</a>
        </div>
      </div>
    </div>
  </div>
  <div className="col-9">
    <div>
      <div style={{ marginBottom: 40 }}>
        <h2 className="meusProjetos">Título do Projeto </h2>
      </div>
      <div
        style={{ marginBottom: 50, marginLeft: "-30px" }}
        className="d-flex row wrap flex-row"
      >
        <div
          className="col-3 d-flex justify-content-center"
          style={{
            borderRight: "1px solid #BDC1CA",
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
            fontFamily: "Inter, serif",
            fontSize: 14,
            fontWeight: 400
          }}
        >
          <div style={{ display: "flex", flexFlow: "column" }}>
            Pendencias (Total)
            <a
              style={{
                marginTop: 15,
                fontFamily: "Archivo, serif",
                fontSize: 32,
                fontWeight: 600
              }}
            >
              10
            </a>
          </div>
        </div>
        <div
          className="col-3 d-flex justify-content-center"
          style={{
            borderRight: "1px solid #BDC1CA",
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
            fontFamily: "Inter, serif",
            fontSize: 14,
            fontWeight: 400
          }}
        >
          <div style={{ display: "flex", flexFlow: "column" }}>
            <div style={{ display: "flex" }}>
              Prioridade:{" "}
              <div
                className="red"
                style={{
                  width: 45,
                  height: 24,
                  backgroundColor: "#FDF2F2",
                  borderRadius: 14,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingRight: 5,
                  marginLeft: 5
                }}
              >
                <i
                  className="bi bi-arrow-up-short"
                  style={{ paddingBottom: 6, fontSize: 18 }}
                />
                <a style={{ paddingBottom: 2 }}>Alta </a>
              </div>{" "}
            </div>
            <a
              style={{
                marginTop: 15,
                fontFamily: "Archivo, serif",
                fontSize: 32,
                fontWeight: 600
              }}
            >
              10
            </a>
          </div>
        </div>
        <div
          className="col-3 d-flex justify-content-center"
          style={{
            borderRight: "1px solid #BDC1CA",
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
            fontFamily: "Inter, serif",
            fontSize: 14,
            fontWeight: 400
          }}
        >
          <div style={{ display: "flex", flexFlow: "column" }}>
            <div style={{ display: "flex" }}>
              Prioridade:{" "}
              <div
                className="red"
                style={{
                  height: 24,
                  backgroundColor: "#FEF6F1",
                  borderRadius: 14,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 71,
                  paddingRight: 5,
                  marginLeft: 5
                }}
              >
                <i
                  className="bi bi-arrow-right-short"
                  style={{ paddingBottom: 6, fontSize: 18 }}
                />
                <a style={{ paddingBottom: 2 }}>Média </a>
              </div>{" "}
            </div>
            <a
              style={{
                marginTop: 15,
                fontFamily: "Archivo, serif",
                fontSize: 32,
                fontWeight: 600
              }}
            >
              10
            </a>
          </div>
        </div>
        <div
          className="col-3 d-flex justify-content-center"
          style={{
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
            fontFamily: "Inter, serif",
            fontSize: 14,
            fontWeight: 400
          }}
        >
          <div style={{ display: "flex", flexFlow: "column" }}>
            <div style={{ display: "flex" }}>
              Prioridade:{" "}
              <div
                className="red"
                style={{
                  height: 24,
                  backgroundColor: "#FEF9EE",
                  borderRadius: 14,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 70,
                  paddingRight: 5,
                  marginLeft: 5
                }}
              >
                <a style={{ paddingBottom: 2 }}>Pendente </a>
              </div>{" "}
            </div>
            <a
              style={{
                marginTop: 15,
                fontFamily: "Archivo, serif",
                fontSize: 32,
                fontWeight: 600
              }}
            >
              10
            </a>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "space-between"
        }}
      >
        <div
          style={{
            fontFamily: "Archivo, serif",
            fontWeight: "bold",
            fontSize: 24
          }}
        >
          Pendências
        </div>
        <div style={{ display: "flex", gap: 16 }}>
          <div
            style={{
              backgroundColor: "#FAEDFF",
              width: 36,
              height: 36,
              borderRadius: 6,
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <img style={{ width: 14, height: 13 }} src="./listicon.png" />
          </div>
          <div
            style={{
              backgroundColor: "#F8F9FA",
              width: 36,
              height: 36,
              borderRadius: 6,
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <img style={{ width: 12, height: 15 }} src="./gridicon.png" />
          </div>
        </div>
      </div>










{filtrados.map((data, index)=> {

return <>


<div style={{ marginTop: 20 }}>
        {" "}
        {/* COL 12 SISTEM */}
        <div
          style={{
            border: "1px #DEE1E6 solid",
            borderRadius: 12,
            padding: 18,
            height: 168
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              height: 40
            }}
          >
            <div
              style={{
                display: "flex",
                flexFlow: "row nowrap",
                justifyContent: "center",
                alignContent: "center",
                fontFamily: "Convergence, serif",
                fontSize: 18
              }}
            >
              {data.nome}
              <div
                className="red"
                style={{
                  height: 24,
                  backgroundColor: "#FEF6F1",
                  borderRadius: 14,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 71,
                  paddingRight: 5,
                  marginLeft: 15,
                  color: "#AE530D"
                }}
              >
                <i
                  className="bi bi-arrow-right-short"
                  style={{ paddingBottom: 6, fontSize: 18 }}
                />
                <a style={{ paddingBottom: 2 }}>{data.prioridade[0].toUpperCase()+data.prioridade.slice(1,data.prioridade.length)} </a>
              </div>{" "}
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  fontFamily: "Inter, serif",
                  fontSize: 12
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    justifyContent: "center"
                  }}
                >
                  {" "}
                  Status:
                </div>{" "}
                <div
                  className="red"
                  style={{
                    height: 24,
                    backgroundColor: "#FEF9EE",
                    borderRadius: 14,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 70,
                    paddingRight: 5,
                    marginLeft: 5
                  }}
                >
                  <a style={{ paddingBottom: 2, color: "#98690C" }}>
                    {data.status}{" "}
                  </a>
                </div>{" "}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              marginTop: 10
            }}
          >
            <div
              style={{
                height: 70,
                display: "flex",
                flexFlow: "column",
                textAlign: "center",
                justifyContent: "center",
                backgroundColor: "#F8F9FA",
                borderRadius: 6,
                padding: "0px 45px"
              }}
            >
              <a
                style={{
                  fontFamily: "Inter, serif",
                  fontSize: 14,
                  fontWeight: "bold"
                }}
              >
                Mecânica
              </a>
              <a style={{ fontFamily: "Inter, serif", fontSize: 12 }}>
                Requisitante
              </a>
            </div>
            <div
              style={{
                height: 70,
                display: "flex",
                flexFlow: "column",
                textAlign: "center",
                justifyContent: "center",
                backgroundColor: "#F8F9FA",
                borderRadius: 6,
                padding: "0px 45px"
              }}
            >
              <a
                style={{
                  fontFamily: "Inter, serif",
                  fontSize: 14,
                  fontWeight: "bold"
                }}
              >
                Civil, Tubulação
              </a>
              <a style={{ fontFamily: "Inter, serif", fontSize: 12 }}>
                Responsável pela Solução
              </a>
            </div>
            <div
              style={{
                height: 70,
                display: "flex",
                flexFlow: "column",
                textAlign: "center",
                justifyContent: "center",
                backgroundColor: "#F8F9FA",
                borderRadius: 6,
                padding: "0px 45px"
              }}
            >
              <a
                style={{
                  fontFamily: "Inter, serif",
                  fontSize: 14,
                  fontWeight: "bold"
                }}
              >
                24/02/2025
              </a>
              <a style={{ fontFamily: "Inter, serif", fontSize: 12 }}>
                Data de Criação
              </a>
            </div>
            <div
              style={{
                width: 154,
                height: 36,
                backgroundColor: "#574A69",
                borderRadius: 10,
                fontFamily: "inter, serif",
                fontSize: 14,
                color: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: 'pointer'
              }}
              onClick={()=> navigate(`/ordem?prjid=${parametroId}&ordem=${data.nome}`)}
            >
              <a style={{ fontSize: 14, fontWeight: 200, marginRight: 4 }}>
                <i className="bi bi-search" />{" "}
              </a>{" "}
              Ver Pendência
            </div>
            <div
              style={{
                width: 170,
                height: 36,
                backgroundColor: "#574A69",
                borderRadius: 10,
                fontFamily: "inter, serif",
                fontSize: 14,
                color: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <a style={{ fontSize: 14, fontWeight: 200, marginRight: 4 }}>
                <i className="bi bi-check2" />{" "}
              </a>{" "}
              Finalizar Pendência
            </div>
          </div>
        </div>
      </div>




</>

})}




















    </div>
  </div>
</div>







        </div>
    )
}
export default OrdensDeServico;
