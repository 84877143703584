



import { useNavigate } from 'react-router-dom';
import HomeLf from './HomeLf';



var runtime = 0;
function Sobre() {


const navigate = useNavigate();



if(window.location.href.slice(window.location.href.length - 1, window.location.href.length) != '/') {
  document.querySelector('.oisumida').style.display = 'none';
} else {

  document.querySelector('.oisumida').style.display = 'block';
  
}





  return (
    <>
    
<HomeLf/>


  <h4 className="py-4 text-center"
      style={{color: '#06BBCC', fontSize: '30px', fontWeight: '800', marginTop: '125px'}}
    
   >Sobre o Lumera 3D</h4>
    

      <div  >
      <div style={{marginBottom: "50px", paddingLeft: '10%', paddingRight: '10%', color: '#666', fontSize: '20px'}}>

    
    <div style={{textAlign: 'center', width: '100%'}}>

    
O Lumera 3D é a solução ideal para quem busca organização, eficiência e <br/>
inovação na gestão de projetos 3D. Desenvolvido com foco em simplificar <br/>
processos e acabar com o caos de e-mails e planilhas desatualizadas, o Lumera <br/>
3D oferece uma plataforma completa para controle de pendências e <br/>
compartilhamento de modelos 3D.  <br/> <br/>
Nosso objetivo é facilitar a comunicação e a colaboração entre equipes,  <br/>
eliminando ruídos e centralizando informações importantes em um único lugar. <br/>
Seja você um profissional de engenharia, arquitetura, design ou qualquer área que <br/>
trabalhe com modelos 3D, o Lumera 3D foi criado para transformar a maneira <br/>
como você gerencia suas demandas. <br/>  <br/>
O que torna o Lumera 3D único? <br/> <br/>
• Gestão centralizada de tarefas: Acompanhe todas as pendências de cada <br/> 
disciplina do seu projeto em uma interface intuitiva e organizada. <br/> <br/>
• Upload e compartilhamento de modelos 3D: Carregue seus arquivos <br/> 
diretamente na plataforma e compartilhe com sua equipe, garantindo que <br/>
todos tenham acesso às versões mais recentes. <br/>  <br/>
• Eliminação de e-mails desnecessários: Diga adeus às longas trocas de emails e à dificuldade de encontrar informações importantes. Aqui, tudo <br/> 
está ao seu alcance. <br/> <br/>
• Simplicidade e eficiência: Interface amigável, fácil de usar e desenvolvida <br/>
para otimizar o seu tempo. <br/> <br/>
Nosso compromisso <br/> <br/>
O Lumera 3D é mais do que uma ferramenta, é um parceiro no sucesso dos seus <br/>
projetos. Inspirados pela perfeição e estética clássica representada no nosso <br/>
símbolo, acreditamos que a organização e a funcionalidade podem, sim, andar <br/>
lado a lado com a beleza e a inovação. <br/>
Transforme a maneira como você trabalha com modelos e projetos 3D. <br/>
Experimente o Lumera 3D e veja o futuro da gestão de projetos. <br/>




    </div>
      </div>
    </div>
 

 
  </>
  );
}

export default Sobre;






