import React, { useEffect, useState } from 'react';
import HomeLf from './HomeLf';
import './projetos.css';
import { db, firebaseAuth } from '../utils/firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import Background from 'three/examples/jsm/renderers/common/Background.js';
import { Center } from '@react-three/drei';
import axios from 'axios';

import listicon from "../assets/version2/listicon.png";
import gridicon from "../assets/version2/gridicon.png";
import atentionicon from "../assets/version2/atentionicon.png";
import msgred from "../assets/version2/msgred.png";
import upload3d from "../assets/version2/upload3d.png";
import boxpendencias from "../assets/version2/boxpendencias.png";
import editarprojeto from "../assets/version2/editarprojeto.png";
import colaboradores from "../assets/version2/colaboradores.png";


function Main(params) {

  const navigate = useNavigate();


  const [current, setCurrent] = useState('');
  const [dataA, setDataA] = useState([]);
  const [filtrados, setFiltrados] = useState([]);

var count01 = 0;


async function master(params) {

      

  var objMaster = [];
  var objMaster2 = [];

   


  onAuthStateChanged(firebaseAuth, async (currentUser) => {
    if (currentUser) {
      var usuarioLogado = currentUser.email;
      setCurrent(usuarioLogado);
    };
  });







        await getDocs(collection(db, "projetos"))
            .then((querySnapshot)=>{               
                const newData = querySnapshot.docs
                    .map((doc) => ({...doc.data(), id:doc.id }));
                    for (let i = 0; i < newData.length; i++) {                     
                           objMaster2.push(newData[i]);                     
                    }
                var contas = [];           
                      var arraya = [...Object.values(objMaster2)];           
                    var criados = [];                      
                    var emailatual = firebaseAuth.currentUser.email;
                    for (let i = 0; i < arraya.length; i++) {                                           
                   if(arraya[i].criador == emailatual){
                      criados.push(arraya[i]);
                   }
                      }

                      criados.sort(function(a, b) {

                        return a.nomeDoProjeto.localeCompare(b.nomeDoProjeto);
                    
                    });



                    setFiltrados(criados);
                    console.log(criados);
            })
        



}


async function editar(params) {
  navigate(`/editar?projeto=${params}`);
}





async function dell(params) {


  await updateDoc(doc(db, 'clientes', `${firebaseAuth.currentUser.email}:projetos`), {
    [params]: {
        nomeDoProjeto: 'nd',
      

    }
  
  });
  navigate(0);
}



function fetchMaster(params) {
  




     const options = {
      url: 'https://lumera-api.vercel.app/tokenviewer',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      }
   
    };
    axios(options)
      .then(response => {
    //    setTokenT(response.data.access_token);
     //   console.log(response.data.access_token);
     window.location.href = `/projeto?urn=${params}&token=${response.data.access_token}`;
      });
  


}



useEffect(()=> {
  master();
},[])

















    return (
        <div>


<HomeLf/>


<div
  className="col-12"
  style={{
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    marginTop: 50
  }}
>
  <div className="col-10">
    <div>
      <div style={{ marginBottom: 40 }}>
        <h2 className="meusProjetos">Meus Projetos </h2>
      </div>
      <div
        style={{
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "space-around",
          marginBottom: 50
        }}
      >
        <div style={{ display: "flex", flexFlow: "column nowrap" }}>
          <div className="tituloCards">Quantos Projetos Participei</div>
          <a className="numerosCards">10</a>
        </div>
        <div
          style={{
            display: "flex",
            flexFlow: "column nowrap",
            paddingLeft: "7rem",
            borderLeft: "1px solid #BDC1CA",
            borderRight: "1px solid #BDC1CA",
            paddingRight: "7rem"
          }}
        >
          <div className="tituloCards" style={{ display: "flex" }}>
            Projetos:{" "}
            <div>
              <a className="emAndamento"> Em Andamento</a>
            </div>
          </div>
          <a className="numerosCards">10</a>
        </div>
        <div style={{ display: "flex", flexFlow: "column nowrap" }}>
          <div className="tituloCards" style={{ display: "flex" }}>
            Projetos:{" "}
            <div>
              <a className="concluido"> Concluído</a>
            </div>
          </div>
          <a className="numerosCards">10</a>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "space-between"
        }}
      >
        <div style={{ display: "flex" }}>
          <div
            style={{
              color: "#985EAC",
              borderBottom: "4px solid #985EAC",
              fontWeight: "bold",
              paddingBottom: 5,
              width: 70,
              textAlign: "center"
            }}
          >
            Todos
          </div>
          <div
            className="seletores"
            style={{
              marginLeft: 0,
              paddingBottom: 5,
              width: 120,
              textAlign: "center"
            }}
          >
            Em Andamento
          </div>
          <div
            className="seletores"
            style={{
              marginLeft: 0,
              paddingBottom: 5,
              width: 80,
              textAlign: "center"
            }}
          >
            Concluído
          </div>
        </div>
        <div style={{ display: "flex", gap: 16 }}>
          <div
            style={{
              backgroundColor: "#FAEDFF",
              width: 36,
              height: 36,
              borderRadius: 6,
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <img style={{ width: 14, height: 13 }} src={listicon}/>
          </div>
          <div
            style={{
              backgroundColor: "#F8F9FA",
              width: 36,
              height: 36,
              borderRadius: 6,
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <img style={{ width: 12, height: 15 }} src={gridicon} />
          </div>
        </div>
      </div>




      {
        filtrados.map((data, index)=> {
          return <>
                <div style={{ marginTop: 20 }}>
        {" "}
        {/* COL 12 SISTEM */}
        <div
          style={{
            border: "1px #DEE1E6 solid",
            borderRadius: 12,
            padding: 18,
            height: 168
          }}
          className="d-flex"
        >
          <div className="col-6" style={{ borderRight: "1px solid #DEE1E6" }}>
            <div className="principalTitle" style={{ marginBottom: 10 }}>
              {data.nomeDoProjeto}
            </div>
            <div className="descricoes" style={{ display: "flex" }}>
              <div style={{ marginRight: 10 }}>
                {" "}
                <img style={{ paddingBottom: 3 }} src={atentionicon} />
              </div>
              {data.descricao}
            </div>
            <div
              className="descricoes"
              style={{ display: "flex", marginTop: 20 }}
            >
              <div style={{ marginRight: 10 }}>
                {" "}
                <img style={{ paddingBottom: 3 }} src={atentionicon} />
              </div>

              {data.disciplinas.map((data2, index) => {
    return <> 
    
      <a> {data2} | <a style={{color: 'transparent'}}>.</a></a>
    </>
    })}
             
            </div>
          </div>
          <div
            style={{ display: "flex", flexFlow: "column nowrap" }}
            className="col-6"
          >
            <div style={{ display: "flex", justifyContent: "end" }}>
              <div
                className="red"
                style={{
                  width: 42,
                  height: 24,
                  backgroundColor: "#ffc3c7",
                  borderRadius: 6,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <img
                  src={msgred}
                  style={{ width: 10, height: 10, marginRight: 3 }}
                />
                <a style={{ paddingBottom: 2 }}>10 </a>
              </div>
              <div
                className="red"
                style={{
                  width: 103,
                  height: 24,
                  borderRadius: 6,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#FEF9EE",
                  margin: "0px 15px"
                }}
              >
                <a
                  style={{
                    paddingBottom: 2,
                    fontSize: 12,
                    color: "#98690C",
                    fontFamily: "Inter, serif"
                  }}
                >
                  Em Andamento{" "}
                </a>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                marginTop: 20
              }}
            >
              {data.urn.length > 2 ? 
              
              
              <>
                    <div
                className="textobotoes"
                style={{
                  display: "flex",
                  flexFlow: "column nowrap",
                  alignItems: "center",
                  color: "#81379A",
                  cursor: 'pointer'
                }}
                
                onClick={()=> fetchMaster(data.urn)}
              >
                {" "}
                <div
                  style={{
                    width: 42,
                    height: 42,
                    backgroundColor: "#985EAC",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  {" "}
                  <img src={upload3d} />
                </div>
                Viewer 3D
              </div>
              
              </>
              
              
              
              
              : 
              
              <div
              className="textobotoes"
              style={{
                display: "flex",
                flexFlow: "column nowrap",
                alignItems: "center",
                color: "#81379A",
                cursor: 'pointer'
              }}
              onClick={()=> navigate(`/uploadmaster?idproject=${data.idProjeto}`)}
            >
              {" "}
              <div
                style={{
                  width: 42,
                  height: 42,
                  backgroundColor: "#985EAC",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                {" "}
                <img src={upload3d} />
              </div>
              Upload 3D
            </div>
              
              
              
              
              }
        
              <div
                className="textobotoes"
                style={{
                  display: "flex",
                  flexFlow: "column nowrap",
                  alignItems: "center",
                  cursor: 'pointer'
                }}
                onClick={()=> navigate(`/ordens?prjid=${data.idProjeto}`)}
              >
                {" "}
                <div
                  style={{
                    width: 42,
                    height: 42,
                    backgroundColor: "#574A69",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <img src={boxpendencias} />{" "}
                </div>
                Pendências
              </div>
              <div
                className="textobotoes"
                style={{
                  display: "flex",
                  flexFlow: "column nowrap",
                  alignItems: "center"
                }}
              >
                {" "}
                <div
                  style={{
                    width: 42,
                    height: 42,
                    backgroundColor: "#574A69",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: 'pointer'
                  }}
                  onClick={()=> navigate(`/editarprojeto?prjid=${data.idProjeto}`)}
                >
                  <img src={editarprojeto} />
                </div>
                Editar Projeto
              </div>
              <div
                className="textobotoes"
                style={{
                  display: "flex",
                  flexFlow: "column nowrap",
                  alignItems: "center",

                }}
              >
                {" "}
                <div
                  style={{
                    width: 42,
                    height: 42,
                    backgroundColor: "#574A69",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: 'pointer'
                  }}
                  onClick={()=> navigate(`/adicionarcolaborador?prjid=${data.idProjeto}`)}
                >
                  <img src={colaboradores} />
                </div>
                Colaboradores
              </div>
            </div>
          </div>
        </div>
      </div>
          </>
        })
      }

      <div style={{ marginTop: 20 }}>
        {" "}
        {/* COL 12 SISTEM */}
        <div
          style={{
            border: "1px #DEE1E6 solid",
            borderRadius: 12,
            padding: 18,
            height: 168
          }}
          className="d-flex"
        >
          <div className="col-6" style={{ borderRight: "1px solid #DEE1E6" }}>
            <div className="principalTitle" style={{ marginBottom: 10 }}>
              Titulo projeto
            </div>
            <div className="descricoes" style={{ display: "flex" }}>
              <div style={{ marginRight: 10 }}>
                {" "}
                <img style={{ paddingBottom: 3 }} src={atentionicon} />
              </div>
              Descrição do Projeto aqui descrição aqui
            </div>
            <div
              className="descricoes"
              style={{ display: "flex", marginTop: 20 }}
            >
              <div style={{ marginRight: 10 }}>
                {" "}
                <img style={{ paddingBottom: 3 }} src={atentionicon} />
              </div>
              Aqui são as disciplinas do projeto
            </div>
          </div>
          <div
            style={{ display: "flex", flexFlow: "column nowrap" }}
            className="col-6"
          >
            <div style={{ display: "flex", justifyContent: "end" }}>
              <div
                className="red"
                style={{
                  width: 42,
                  height: 24,
                  backgroundColor: "#ffc3c7",
                  borderRadius: 6,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <img
                  src={msgred}
                  style={{ width: 10, height: 10, marginRight: 3 }}
                />
                <a style={{ paddingBottom: 2 }}>10 </a>
              </div>
              <div
                className="red"
                style={{
                  width: 78,
                  height: 24,
                  borderRadius: 6,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#EEFDF3",
                  margin: "0px 15px"
                }}
              >
                <a
                  style={{
                    paddingBottom: 2,
                    fontSize: 12,
                    color: "#117B34",
                    fontFamily: "Inter, serif"
                  }}
                >
                  Concluído{" "}
                </a>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                marginTop: 20
              }}
            >
              <div
                className="textobotoes"
                style={{
                  display: "flex",
                  flexFlow: "column nowrap",
                  alignItems: "center",
                  color: "#81379A"
                }}
              >
                {" "}
                <div
                  style={{
                    width: 42,
                    height: 42,
                    backgroundColor: "#985EAC",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  {" "}
                  <img src={upload3d} />
                </div>
                Upload 3D
              </div>
              <div
                className="textobotoes"
                style={{
                  display: "flex",
                  flexFlow: "column nowrap",
                  alignItems: "center"
                }}
              >
                {" "}
                <div
                  style={{
                    width: 42,
                    height: 42,
                    backgroundColor: "#574A69",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <img src={boxpendencias} />{" "}
                </div>
                Pendências
              </div>
              <div
                className="textobotoes"
                style={{
                  display: "flex",
                  flexFlow: "column nowrap",
                  alignItems: "center"
                }}
              >
                {" "}
                <div
                  style={{
                    width: 42,
                    height: 42,
                    backgroundColor: "#574A69",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <img src={editarprojeto} />
                </div>
                Editar Projeto
              </div>
              <div
                className="textobotoes"
                style={{
                  display: "flex",
                  flexFlow: "column nowrap",
                  alignItems: "center"
                }}
              >
                {" "}
                <div
                  style={{
                    width: 42,
                    height: 42,
                    backgroundColor: "#574A69",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <img src={colaboradores} />
                </div>
                Colaboradores
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>








        </div>
    )
}
export default Main;