import logo from '../img/logo.png';
import React, { useEffect, useState } from 'react'
import { db, firebaseAuth, storage } from "../utils/firebase-config";
import { createUserWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { doc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import HomeLf from './HomeLf';
import './register.css';



import cadastropeople from "../assets/version2/cadastropeople.png";
import cadastrodetalhe from "../assets/version2/cadastrodetalhe.png";
import cadastroarroba from "../assets/version2/cadastroarroba.png";



//import Header from './Header';

// REGISTER MASTER

function Register() {

    const navigate = useNavigate();






    if(window.location.href.slice(window.location.href.length - 1, window.location.href.length) != '/') {
      document.querySelector('.oisumida').style.display = 'none';
  } else {
  
      document.querySelector('.oisumida').style.display = 'block';
      
  }










    const [deslogado, setDeslogado] = useState(false);



    
    const [stepby, setStepby] = useState('none')
    const [page01, setPage01] = useState('block');
    const [page02, setPage02] = useState('none');
    const [page03, setPage03] = useState('none');
    const [page04, setPage04] = useState('none');
    const [page05, setPage05] = useState('none');
    const [page06, setPage06] = useState('none');
    const [page07, setPage07] = useState('none');



    const [emaill, setEmaill] = useState('');
    const [senha, setSenha] = useState('');
    const [senhaCnf, setSenhaCnf] = useState('');
    const [nome, setNome] = useState('');
    const [cidade, setCidade] = useState('');
    const [idade, setIdade] = useState('');
    const [telefone, setTelefone] = useState('');
    const [sobre, setSobre] = useState('');

    const [precoHr, setPrecoHr] = useState('');
    const [credito, setCredito] = useState(false);
    const [pix, setPix] = useState(false);
    const [dinheiro, setDinheiro] = useState(false);
    const [ligacao, setLigacao] = useState(false);
    const [whatsapp, setWhatsapp] = useState(false);
    const [videochamada, setVideochamada] = useState(false);





    const [imageUpload, setImageUpload] = useState();
    const [imageUpload2, setImageUpload2] = useState();
    const [imageUpload3, setImageUpload3] = useState();
    const [imageUpload4, setImageUpload4] = useState();
    const [videoUpload, setVideoUpload] = useState();


    const [ativo, setAtivo] = useState(true);



    const [url01, setUrl01] = useState('');
    const [url02, setUrl02] = useState('');
    const [url03, setUrl03] = useState('');
    const [url04, setUrl04] = useState('');
    const [url05, setUrl05] = useState('');

    const [msgs, setMsgS] = useState('');
    const [msgs2, setMsgS2] = useState('');
    const [msgs3, setMsgS3] = useState('');
    const [msgs4, setMsgS4] = useState('');
    const [msgs5, setMsgS5] = useState('');
    const [msgs6, setMsgS6] = useState('');





    async function setMaster(params) {
        
        if(params == 1){
        

          
              
              if(senha == senhaCnf){
            
              try {
              await createUserWithEmailAndPassword(firebaseAuth, emaill, senha);
               setPage01('none');
               setPage02('block');
               setPage03('none');
               setPage04('none');
              } catch (err) {
                setMsgS(err.code);
              }
            } else {
              setMsgS('Senhas não correspondem!')
            }
        }
        if(params == 2){
              if(nome.length > 3 && cidade.length> 3 && idade.length > 1 && telefone.length > 8 
              
                ) {
                  setPage01('none');
                  setPage02('none');
                  setPage03('block');
                  setPage04('none');
                } else {

                  if(nome.length <= 3){setMsgS2('Preencha seu nome!') } else {setMsgS2('')}
                  if(cidade.length <= 3){setMsgS3('Preencha sua cidade!') } else {setMsgS3('')}
                  if(idade.length <= 1){setMsgS4('Preencha sua idade!') } else {setMsgS4('')}
                  if(telefone.length <= 7){setMsgS5('Preencha seu telefone!') } else {setMsgS5('')}
                  if(sobre.length <= 100){setMsgS6('Sua bio deve ter mais de 100 caracteres') } else {setMsgS6('')}

                }
   
        }

        if(params == 3){
            setPage01('none');
            setPage02('none');
            setPage03('none');
            setPage04('block');
        }
        if(params == 4){
            setPage01('none');
            setPage02('none');
            setPage03('none');
            setPage04('none');
            setPage05('block');
            
        }

        if(params == 5){
            setPage01('none');
            setPage02('none');
            setPage03('none');
            setPage04('none');
            setPage05('block');
     
        }

        if(params == 6){
            setPage01('none');
            setPage02('none');
            setPage03('none');
            setPage04('none');
            setPage05('none');
            setPage06('block');
        }
        if(params == 7){
            setPage01('none');
            setPage02('none');
            setPage03('none');
            setPage04('none');
            setPage05('none');
            setPage06('none');
            setPage07('block');
        }

    }














    async function finishMaster(params) {


      var data55 = new Date();
    

        await setDoc(doc(db, 'clientes', `${emaill}:projetos`), {
            int: {
                nomeDoProjeto: 'in',
                colaboradores: []

            }
          });



        
        await setDoc(doc(db, 'clientes', `${emaill}`), {
            nome: nome,
            cidade: 'cidade',
            idade: 25,
            telefone: 'telefone',
            sobre: 'sobre',  
            criacao: data55,
   

          });
         
          
          setTimeout(() => {
            navigate('/');
          }, 1000);

    }

 




   async function enviarImagensVideo(params) {

            finishMaster();
            
        
    }





    const uploadFile = async (params) => {
        if (!params) return;
      

        const imageRef = ref(storage, `clientesMidias/${emaill}/${'foto01'}`);

        uploadBytes(imageRef, params).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setUrl01(url);
       
            });
        }  );

    };

  
    const uploadFile2 = (params) => {
        if (!params) return;
    

        const imageRef = ref(storage, `clientesMidias/${emaill}/${'foto02'}`);

        uploadBytes(imageRef, params).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setUrl02(url);
            });
        }  );

    };


    const uploadFile3 = (params) => {
        if (!params) return;
      

        const imageRef = ref(storage, `clientesMidias/${emaill}/${'foto03'}`);

        uploadBytes(imageRef, params).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setUrl03(url);
            });
        }  );

    };


    const uploadFile4 = (params) => {
        if (!params) return;
     

        const imageRef = ref(storage, `clientesMidias/${emaill}/${'foto04'}`);

        uploadBytes(imageRef, params).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setUrl04(url);
            });
        }  );

    };

    const uploadFile5 = (params) => {
        if (!params) return;
        setVideoUpload(params);

        const imageRef = ref(storage, `clientesMidias/${emaill}/${params.name}`);

        uploadBytes(imageRef, params).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setUrl05(url);
            });
        }  );

    };








    const [logado, setLogado] = useState(false);


    function logOut(params) {
      signOut(firebaseAuth); 
      setLogado(false);
    }

    function master(params) {
        

    onAuthStateChanged(firebaseAuth, async (currentUser) => {
        if (currentUser) {setLogado(true);
        
     
    }
      });
    }


  return (
  
<>


  <div class="card card-1" style={{ display: 'none'}}>
  <div class="card-heading"></div>
  <div class="card-body">
      <h2 class="title">Criar conta</h2>
   
          <div class="input-group"  style={{borderColor: `${msgs.length > 2 ? 'red' : '#CCCCCC'}`, }}>
              <input class="input--style-1" type="text" placeholder="Email" name="Email"
              style={{backgroundColor: 'rgba(111,111,111,0.2)', maxWidth: '400px'}}
               onChange={(e) => setEmaill(e.target.value)  }
              />
          </div>
          <div class="row row-space">
              <div class="col-2">
                  <div class="input-group"  style={{borderColor: `${msgs.length > 2 ? 'red' : '#CCCCCC'}`}}>
                      <input class="input--style-1 js-datepicker" type="password" placeholder="Senha" name="Senha"
                       style={{backgroundColor: 'rgba(111,111,111,0.2)', maxWidth: '400px'}}
                             onChange={(e) => setSenha(e.target.value)  }
                      />
                      <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                  </div>
              </div>

          </div>

      

          <div class="row row-space">
              <div class="col-2">
                  <div class="input-group" style={{borderColor: `${msgs.length > 2 ? 'red' : '#CCCCCC'}`}}>
                      <input class="input--style-1 js-datepicker" type="password" 
                       style={{backgroundColor: 'rgba(111,111,111,0.2)', maxWidth: '400px'}}
                      onChange={(e)=> setSenhaCnf(e.target.value) }
                      placeholder="Confirmar senha" name="Confirmar senha"/>
                      <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                
                
                
                  </div>
                  
              </div>
            
          </div>
          <div class="row row-space col-4">
              
       

              <button class="btn button hoverbtn"
            style={{minWidth: '200px', backgroundColor: '#4646D6'}}
               onClick={()=> setMaster(1)}
             
               >Cadastrar-se</button>
                {
                    msgs.length > 2 ? 
<a style={{color: 'red', fontSize: '15px', padding: '10px', margin: '5px 0px', marginTop: '15px', marginLeft: '0px', backgroundColor: '#999'}}>{msgs.replace('auth/', '')}</a>          
                    : ''
                } 
                    
            
          </div>




      
  
  </div>
</div>
  
  



























































<div className="row" style={{display: 'flex', justifyContent: 'center'}}>
  <div className="col-md-2 col-sm-12 flex-column align-items-end sumir"></div>
  <div
    className="col-md-4 col-sm-12 vocedevolta d-flex flex-column align-items-md-start align-items-sm-center"
    style={{ paddingTop: 120 }}
  >
    Solicite acesso
    <br /> ao Lumera 3D
    <div className="seespaco">
      {" "}
      <br />
      Cadastre-se e nossa equipe entrará em contato
      <br /> com você.
    </div>
    <img
      style={{ marginTop: 40, width: 50, height: 50 }}
      src={cadastropeople}
    />
  </div>
  <div
    className="col-md-4 col-sm-12 titulosCadastro d-flex flex-column cardlogin align-items-sm-center align-items-md-center"
    style={{ paddingTop: 100 }}
  >
    <div
      className="quadradologin row row-cols-1"
      style={{ alignItems: "center", paddingTop: 60, justifyContent: "center", height: '900px'}}
    >
      <div
        className="col-10"
        style={{
          display: "column",
          justifyContent: "center",
          marginTop: "-40px",
          color: "#424856"
        }}
      >
        Nome completo
        <div
          style={{
            display: "flex",
            flexFlow: "column",
            border: "solid #BDC1CA 1px",
            paddingLeft: 10,
            paddingRight: 140,
            paddingTop: 2,
            paddingBottom: 2,
            borderRadius: 6,
            marginBottom: 14
          }}
          onChange={(e) => setNome(e.target.value)  }
        >
          {" "}
          <input
            className="inputLogin fontLoginInputPlace"
            style={{ border: "none", height: 43, width: 345 }}
            placeholder="Insira seu nome e sobrenome"
            type="text"
          />{" "}
        </div>
        Empresa
        <div
          style={{
            display: "flex",
            flexFlow: "column",
            border: "solid #BDC1CA 1px",
            paddingLeft: 10,
            paddingRight: 140,
            paddingTop: 2,
            paddingBottom: 2,
            borderRadius: 6,
            marginBottom: 14
          }}
        >
          {" "}
          <input
            className="inputLogin fontLoginInputPlace"
            style={{ border: "none", height: 43, width: 345 }}
            placeholder="Insira o nome da sua empresa"
            type="text"
          />{" "}
        </div>
        Email profissional
        <div
          style={{
            display: "flex",
            flexFlow: "column",
            border: "solid #BDC1CA 1px",
            paddingLeft: 10,
            paddingRight: 140,
            paddingTop: 2,
            paddingBottom: 2,
            borderRadius: 6,
            marginBottom: 16
          }}
        >
          {" "}
          <input
            className="inputLogin fontLoginInputPlace"
            style={{ border: "none", height: 43, width: 345 }}
            placeholder="seuemail@empresa.com"
            type="text"
            onChange={(e) => setEmaill(e.target.value)  }
          />{" "}
        </div>
        <div
          style={{
            height: 80,
            width: "100%",
            backgroundColor: "#197dca21",
            marginBottom: 12
          }}
        >
          <img src={cadastrodetalhe} />{" "}
          <a style={{ fontWeight: 400, fontSize: 14 }}>
            Use seu e-mail corporativo para convidar membros de sua equipe.
          </a>
        </div>
        Senha
        <div
          style={{
            display: "flex",
            flexFlow: "column",
            border: "solid #BDC1CA 1px",
            paddingLeft: 10,
            paddingRight: 140,
            paddingTop: 2,
            paddingBottom: 2,
            borderRadius: 6,
            marginBottom: 14
          }}
        >
          {" "}
          <input
            className="inputLogin fontLoginInputPlace"
            style={{ border: "none", height: 43, width: 345 }}
            placeholder="Insira uma senha forte"
            type="password"
            onChange={(e) => setSenha(e.target.value)  }
          />{" "}
        </div>
        Repetir Senha
        <div
          style={{
            display: "flex",
            flexFlow: "column",
            border: "solid #BDC1CA 1px",
            paddingLeft: 10,
            paddingRight: 140,
            paddingTop: 2,
            paddingBottom: 2,
            borderRadius: 6,
            marginBottom: 25
          }}
        >
          {" "}
          <input
            className="inputLogin fontLoginInputPlace"
            style={{ border: "none", height: 43, width: 345 }}
            placeholder="Repita a senha"
            type="password"
            onChange={(e)=> setSenhaCnf(e.target.value) }
          />{" "}
        </div>
        <div
          className="fontLoginFoget"
          style={{ marginLeft: 2, marginBottom: 14 }}
        >
          {" "}
          <input type="checkbox" style={{ marginRight: 6 }} />
          Li e concordo com os Termos de Uso.
        </div>
        <div
          className="fontLoginFoget"
          style={{ marginLeft: 2, marginBottom: 30 }}
        >
          {" "}
          <input type="checkbox" style={{ marginRight: 6 }} />
          Li e concordo com a Política de Privacidade..
        </div>
      </div>
      <div className="col-10" style={{ marginTop: "-40px", marginBottom: 24 }}>
        <button className="botaoLogin"
          onClick={()=> finishMaster()}
        >Cadastrar</button>
      </div>
      <div
        className="col-10"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "-60px"
        }}
      >
        <div className="fontLoginFoget" style={{ marginRight: 22 }}>
          {" "}
          Já possui conta na Lumera 3D?
        </div>
        <a className="loginEsqueciSenha" style={{ fontSize: 14 }}>
          Log in
        </a>
      </div>
      <a
        style={{
          fontWeight: 300,
          fontSize: 12,
          textAlign: "center",
          marginBottom: "-14px"
        }}
      >
        © 2025 Lumera 3D - Uma marca Kollabity Engineering Data
      </a>
    </div>
  </div>
  <div
    className="col-md-2 col-sm-12"
    style={{ paddingTop: 260, paddingLeft: 80 }}
  >
    <img src={cadastroarroba} />
  </div>
</div>














<div style={{width: '100%', height: '100px', color: 'transparent'}}>Lumera3d</div>

































  





        <div class="card card-1" style={{marginTop: '-100px', display: page03}}>
            <div class="card-heading"></div>
            <div class="card-body">
                <h2 class="title">Preços / Contato</h2>
           
                    <div class="input-group" style={{width: '150px', display: 'flex', flexFlow: 'row nowrap'}}>
                        <a style={{marginTop: '9px', marginRight: '8px', color: '#666'}}>R$:  </a><input
                         onChange={(e) => setPrecoHr(e.target.value)  }
                        class="input--style-1" type="text" placeholder="Preço por hora" name="preço por hora"/>
                    </div>
                    <div class="row row-space">
                        <div class="col-2">
                            <div class="input-group" > <h5 style={{marginTop: '12px', marginRight: '15px', color: '#555'}}>Pagamentos:</h5>
                         <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Cartão de crédito 
                         
                         <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setCredito(e.target.checked)  }
  />
</div>
                         
                         
                         
                         </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Pix 

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setPix(e.target.checked)  }
  />
</div>


</div>
<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Dinheiro 

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setDinheiro(e.target.checked)  }
  />
</div>



</div>
                           
                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>
                    <div class="row row-space">
                        <div class="col-2">
                            <div class="input-group" > <h5 style={{marginTop: '12px', marginRight: '15px', color: '#555'}}>Contato:</h5>
                         <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Ligação 
                         
                         <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setLigacao(e.target.checked)  }
  />
</div>
                         
                         
                         </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Whatsapp 

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setWhatsapp(e.target.checked)  }
  />
</div>



</div>
<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Videochamada 

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setVideochamada(e.target.checked)  }
  />
</div>


</div>
                           
                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>

              











                    <div class="row row-space col-4">
                        
                 

                        <button class="btn button"
                          style={{minWidth: '200px', color: 'white', backgroundColor: '#4646D6'}}
                         type="submit"
                         onClick={()=> setMaster(3)}
                         >Continuar</button>

                      
                      
                    </div>

            
            </div>
        </div>
 

 





        <div class="card card-1" style={{marginTop: '-100px', display: page04}}>
            <div class="card-heading"></div>
            <div class="card-body">
                <h2 class="title">Aparência</h2>
           
                 
                    <div class="row row-space">
                        <div class="col-7">
                            <div class="input-group" > <h5 style={{marginTop: '12px', marginRight: '15px', color: '#555'}}>Etnia:</h5>
                         <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Branca 
                         
                         <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"

  />
</div>
                         
                         
                         </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Latina 

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"

  />
</div>



</div>
<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Mulata 

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"

  />
</div>



</div>
                           
                           <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Negra 
                           
                           <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"

  />
</div>

                           
                           </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Oriental 

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"

  />
</div>


                           
                           </div>

                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>



                    <div class="row row-space">
                        <div class="col-2">
                            <div class="input-group" > <h5 style={{marginTop: '12px', marginRight: '15px', color: '#555'}}>Cabelo:</h5>
                         <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Loira 
                         
                         <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"

  />
</div>
                         
                         
                         </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Morena 

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"

  />
</div>

</div>
<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Ruiva 


<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"

  />
</div>



</div>
                           
                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>




                    <div class="row row-space">
                        <div class="col-2">
                            <div class="input-group" > <h5 style={{marginTop: '12px', marginRight: '15px', color: '#555'}}>Estatura:</h5>
                         <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Alta 
                         
                         
                         <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
 
  />
</div>
                         
                         
                         </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Mignon 

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"

  />
</div>


</div>

                           
                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>



                    <div class="row row-space">
                        <div class="col-2">
                            <div class="input-group" > <h5 style={{marginTop: '12px', marginRight: '15px', color: '#555'}}>Corpo:</h5>
                         <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Gordinha 
                         
                         
                         <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"

  />
</div>
                         
                         
                         
                         
                         </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Magra 


<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"

  />
</div>



</div>

                           
                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>



                    <div class="row row-space">
                        <div class="col-2">
                            <div class="input-group" > <h5 style={{marginTop: '12px', marginRight: '15px', color: '#555'}}>Seios:</h5>
                         <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Silicone 
                         
                         
                         <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
 
  />
</div>
                         
                         
                         
                         </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Natual 



<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>


</div>

                           
                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>


                    <div class="row row-space">
                        <div class="col-2">
                            <div class="input-group" > <h5 style={{marginTop: '12px', marginRight: '15px', color: '#555'}}>Púbis:</h5>
                         <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Peludo 
                         
                         <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>
                         
                         
                         </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Depilado 

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>


</div>

                           
                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>














                    <div class="row row-space col-4">
                        
                 

                        <button class="btn button"
                          style={{minWidth: '200px', color: 'white',backgroundColor: '#4646D6'}}
                         type="submit"
                         onClick={()=> setMaster(4)}
                         >Continuar</button>

                      
                      
                    </div>

            
            </div>
        </div>
 



        <div class="card card-1" style={{marginTop: '-100px', display: page05}}>
            <div class="card-heading"></div>
            <div class="card-body">
                <h2 class="title">Serviços</h2>
           
                 
                    <div class="row row-space">
                        <div class="col-7">
                            <div class="input-group" > <h5 style={{marginTop: '12px', marginRight: '15px', color: '#555'}}>Serviços gerais:</h5>
                         <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Beijos na boca
                         
                         <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>
                         
                         
                         </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Duplas 

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>

</div>
<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Ejaculação corpo 


<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>


</div>
                           
                           <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Facial 
                           
                           
                           <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>
                           
                           
                           
                           </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Fantasias e disfarces


<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>



</div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Massagem erótica

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>


</div>
<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Namoradinha


<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>


</div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Oral até o final

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>


</div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Oral com camisinha


<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>


</div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Oral sem camisinha

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>


</div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>PSE


<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>



</div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Sexo anal

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>


</div>


                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>



            


          

                    <div class="row row-space">
                        <div class="col-7">
                            <div class="input-group" > <h5 style={{marginTop: '12px', marginRight: '15px', color: '#555'}}>Serviços especiais:</h5>
                         <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Beijo negro 
                         
                         
                         <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>
                         
                         </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Chuva dourada 
<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>


</div>
<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Chuva negra 


<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>



</div>
                           
                           <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Fetichismo 
                           
                           
                           
                           <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>
                           
                           
                           
                           </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Garganta profunda

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>


</div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Sado duro


<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>


</div>
<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Sado suave

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>


</div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Squirting

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>

</div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Strap on



<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>


</div>




                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>




                    <div class="row row-space">
                        <div class="col-7">
                            <div class="input-group" > <h5 style={{marginTop: '12px', marginRight: '15px', color: '#555'}}>Lugar:</h5>
                         <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>A domicilio
                         
                         
                         <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>
                         
                         </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Clube de Swing 

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>



</div>
<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Com local 



<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>



</div>
                           
                           <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Despedidas de solteiro 
                           
                           <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>
                           
                           
                           
                           </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Festas e eventos

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>


</div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Hotel

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>




</div>
<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Jantar romântico


<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>



</div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Viagens


<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
  />
</div>





</div>





                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>









                    <div class="row row-space col-4">
                        
                 

                        <button class="btn button"
                          style={{minWidth: '200px', color: 'white', backgroundColor: '#4646D6'}}
                         type="submit"
                         onClick={()=> setMaster(6)}
                         >Continuar</button>

                      
                      
                    </div>

            
            </div>
        </div>
 




        <div class="card card-1" style={{marginTop: '-100px', display: page06}}>
            <div class="card-heading"></div>
            <div class="card-body">
                <h2 class="title">Fotos</h2>
           
                    <div class="input-group" style={{width: '400px'}} >
                  <a>Foto 1 (Perfil)</a>  <input
   type="file"
   onChange={(event) => {
    setImageUpload(event.target.files[0]);
    uploadFile(event.target.files[0]);
   }}
   />
                    </div>

{
    url01.length > 2 ?
    
    <div class="input-group" style={{width: '400px'}} >
    <a>Foto 2</a>  <input
type="file"
onChange={(event) => {
uploadFile2(event.target.files[0]);
}}
/>
      </div>

: ''
}

                   
            
{
  url02.length > 2 ?
  <div class="input-group" style={{width: '400px'}} >
                  <a>Foto 3</a>  <input
   type="file"
   onChange={(event) => {
    uploadFile3(event.target.files[0]);
   }}
   />
                    </div>

  : ''
}
                    
                  


{
    url03.length > 2 ?

    <div class="input-group" style={{width: '400px'}} >
    <a>Foto 4</a>  <input
type="file"
onChange={(event) => {
uploadFile4(event.target.files[0]);
}}
/>
      </div>

    : ''
}

                  




                 







                    <div class="row row-space col-4">
                        
                 
        {url01.length > 2 ?
         <button class="btn button"
         style={{minWidth: '200px', color: 'white', backgroundColor: '#4646D6'}}
         type="submit"
         onClick={()=> finishMaster()}
         >Continuar</button> 
    :
    <button disabled class="btn button"
    type="submit"
    style={{minWidth: '200px', backgroundColor: '#4646D6'}}
    >Continuar</button>

    }
                      
                      
                    </div>

            
            </div>
        </div>


        <div class="card card-1" style={{marginTop: '-100px', display: page07}}>
            <div class="card-heading"></div>
            <div class="card-body">
                <h2 class="title">Fotos</h2>
           
                    <div class="input-group" style={{width: '400px'}} >
                  <a>Foto 1 (Perfil)</a>  <input
   type="file"
   onChange={(event) => {
    setImageUpload(event.target.files[0]);
   }}
   />
                    </div>

                    <div class="input-group" style={{width: '400px'}} >
                  <a>Foto 2</a>  <input
   type="file"
   onChange={(event) => {
    setImageUpload2(event.target.files[0]);
   }}
   />
                    </div>
            

                    
                    <div class="input-group" style={{width: '400px'}} >
                  <a>Foto 3</a>  <input
   type="file"
   onChange={(event) => {
    setImageUpload3(event.target.files[0]);
   }}
   />
                    </div>





                    <div class="input-group" style={{width: '400px'}} >
                  <a>Foto 4</a>  <input
   type="file"
   onChange={(event) => {
    setImageUpload4(event.target.files[0]);
   }}
   />
                    </div>





            







                    <div class="row row-space col-4">
                        
                 

                        <button class="btn button"
                         type="submit"
                         style={{ color: 'white', backgroundColor: '#4646D6'}}
                         onClick={()=> enviarImagensVideo()}
                         >Finalizar Cadastro</button>

                      
                      
                    </div>

            
            </div>
        </div>

        </>
  );
}

export default Register;







