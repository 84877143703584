import React, { useEffect, useState } from 'react';
import '../assets/css/material-dashboard.css'
import HomeLf from './HomeLf';
import { db, firebaseAuth } from '../utils/firebase-config';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';

function CriarOrdem(params) {

  const navigate = useNavigate();


  const [current, setCurrent] = useState('');
  const [dataA, setDataA] = useState([]);
  const [filtrados, setFiltrados] = useState([]);
  const [filtradosB, setFiltradosB] = useState([]);
  const [nomeOrdem, setNomeOrdem] = useState('');
  const [ordemDisciplina, setOrdemDisciplina] = useState([]);
  const [ordemPrioridade, setOrdemPrioridade] = useState('urgente');

var count01 = 0;




const [mapeA, setMapeA] = useState([1]);
const [countMp, setCountMp] = useState(1);






const queryParameters = new URLSearchParams(window.location.search)
const parametroId = queryParameters.get("prjid")



async function master(params) {

      

  var objMaster = [];
  var objMaster2 = [];

   


  onAuthStateChanged(firebaseAuth, async (currentUser) => {
    if (currentUser) {
      var usuarioLogado = currentUser.email;
      setCurrent(usuarioLogado);
    };
  });







        await getDocs(collection(db, "projetos"))
            .then((querySnapshot)=>{               
                const newData = querySnapshot.docs

                    .map((doc) => ({...doc.data(), id:doc.id }));
                    for (let i = 0; i < newData.length; i++) {
        
                   
                           objMaster2.push(newData[i]);
                      
                    }

                    var contas = [];
              
                      var arraya = [...Object.values(objMaster2)];
            
                    var criados = [];
      
                 
                    var emailatual = firebaseAuth.currentUser.email;
                    for (let i = 0; i < arraya.length; i++) {
                    
                        
                   if(arraya[i].idProjeto == parametroId){
                      criados.push(arraya[i]);
                   }

                      }
                      setFiltradosB(criados)
                    setFiltrados(criados[0].disciplinas);
                    console.log('MASTER  ',criados[0].disciplinas);
            })
        



}


async function editar(params) {
  navigate(`/editar?projeto=${params}`);
}





async function dell(params) {


  await updateDoc(doc(db, 'clientes', `${firebaseAuth.currentUser.email}:projetos`), {
    [params]: {
        nomeDoProjeto: 'nd',
      

    }
  
  });
  navigate(0);
}




function enviarPost01() {


  var seletoresM = [];
  for (let index = 0; index < document.querySelectorAll('.seletores').length; index++) {
    seletoresM.push(document.querySelectorAll('.seletores')[index].value);
  }


  fetch('https://lumera-api.vercel.app/criarordem', {
    method: 'POST',
    body: JSON.stringify({
      email: firebaseAuth.currentUser.email,
      parametroId: parametroId,
      nomeOrdem: nomeOrdem,
      ordemDisciplina: seletoresM,
      ordemPrioridade: ordemPrioridade
    }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
     .then((response) => response.json())
     .then((data) => {
        navigate(-1);
     })
     .catch((err) => {
      navigate(-1);
     });
}






useEffect(()=> {
  master();
},[])







var trad = [];

function mapear() {
            
  setCountMp(countMp + 1);

  for (let i = 0; i < countMp; i++) {
      trad.push(i);
      
  }
  trad.push(countMp);
  console.log(trad);
  setMapeA(trad);
}











    return (
        <div>


<HomeLf/>


  <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

    <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" data-scroll="true">
      <div class="container-fluid py-1 px-3">
     
    
      </div>
    </nav>

    <div class="container-fluid py-4">
  
      
      <div class="row mb-4"  style={{marginTop: '20px'}}>
        <div class="col-lg-12 col-md-6 mb-md-0 mb-4">
          <div class="card">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-lg-6 col-7">
                  <h6 style={{color: 'black'}}>Criar pendência</h6>
                  <p class="text-sm mb-0">
                 
            
                  </p>
                </div>
                <div class="col-lg-6 col-5 my-auto text-end">
               
                </div>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nome</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Disciplina</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Prioridade</th>
                      <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
                    </tr>
                  </thead>
                  <tbody>
              
      

<>

 <> 

<tr>
<td>
  <div class="d-flex px-2 py-1">
    <div>

    </div>
    <div class="d-flex flex-column justify-content-center">
      <h6 class="mb-0 text-sm" style={{ padding: '0px 10px', border: 'solid black 1px'}}><input 
      onChange={(e)=> setNomeOrdem(e.target.value)}
      placeholder='Nome'/> </h6>
    </div>
  </div>
</td>
<td>
  <div class="avatar-group mt-2">






  <div style={{ paddingLeft: '10px', maxWidth: '290px', paddingRight: '10px', display: 'flex', flexFlow: 'row wrap', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}> 

<>
                                {mapeA.map((data, index) => {

return <> 




                       
<select
      onChange={(e)=> setOrdemDisciplina(e.target.value)}
      style={{margin: '5px'}}
      className='seletores'
>

<>


  <> 
                           {filtrados.map((data, index) => {

                            return <> 
                            
                            <option value={data}>{data}</option>
                            </>
                            })}</>

 
     
                  </>
</select>
                       
                       
              

</>
})}
                  </>

                  <a style={{color: 'green', fontSize: '28px', marginLeft: '5px', cursor: 'pointer'}}
onClick={()=> mapear()}
>+</a> 


</div> 







  </div>
</td>
<td class="align-middle text-center text-sm">
  <span class="text-xs font-weight-bold">
    
  <select
  style={{fontSize: '14px'}}
  onChange={(e) => {setOrdemPrioridade(e.target.value); console.log(e.target.value)}}
  >
  <option value="baixa">Baixa</option>
  <option value="media">Média</option>
  <option value="alta">Alta</option>


  </select>
    
     </span>
</td>
<td class="align-middle  text-center">
  <div class="progress-wrapper mx-auto">
    <a style={{ cursor: 'pointer', border: '2px black solid', borderRadius: '10px', padding: '10px'}}
    onClick={()=> enviarPost01()}
    > Criar </a>
  
  </div>
</td>
</tr>






</>

                    </>







                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
   
      </div>
      <footer class="footer py-4  ">
        <div class="container-fluid">
          <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-6 mb-lg-0 mb-4">
  
            </div>
     
          </div>
        </div>
      </footer>
    </div>
  </main>






        </div>
    )
}
export default CriarOrdem;
