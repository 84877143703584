import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { db, firebaseAuth } from '../utils/firebase-config';

import logo from "../assets/version2/logo.png";
import detailmenu from "../assets/version2/detailmenu.png";
import peoples from "../assets/version2/peoples.png";


function HomeLf(params) {
    






    if(window.location.href.slice(window.location.href.length - 1, window.location.href.length) != '/') {
        document.querySelector('.oisumida').style.display = 'none';
    } else {
    
        document.querySelector('.oisumida').style.display = 'block';
        
    }





    const navigate = useNavigate();


    const [logado, setLogado] = useState(false);
    function master(params) {
        

        onAuthStateChanged(firebaseAuth, async (currentUser) => {
            if (currentUser) {setLogado(true);
            
         
        } else {
            navigate('/')
        }
          });
        }
    
         async function logOut(params) {
           await navigate('/')
            signOut(firebaseAuth); 
            setLogado(false);
          }

    useEffect(()=> {

        master();


    },[])

return (

<nav>



 <div
  style={{
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #D9D9D9",
    height: 57
  }}
>
  <div style={{ display: "flex", padding: "10px 30px", alignItems: "center" }}>
    <img src={logo} style={{ width: 156, height: 29 }}
    onClick={()=> navigate('/')}
    />
    <div
      style={{
        border: "1px solid #81379A",
        borderRadius: 6,
        backgroundColor: "#9F6DAF",
        marginLeft: 20,
        fontSize: 13,
        color: "#fff",
        fontFamily: "Inter, serif",
        width: 128,
        height: 29,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 8,
        cursor: 'pointer'
      }}
      onClick={()=> navigate('/main')}
    >
      <img src={detailmenu} /> Meus Projetos
    </div>
    <div
      style={{
        fontFamily: "Inter, serif",
        fontSize: 13,
        color: "#424856",
        fontWeight: "bold",
        marginLeft: 30,
        cursor: 'pointer'
      }}
      onClick={()=> navigate('/adicionar')}
      
    >
      <i className="bi bi-plus-circle-fill" style={{ marginRight: 3 }} /> Novo
      Projeto
    </div>
    <div
      style={{
        fontFamily: "Inter, serif",
        fontSize: 13,
        color: "#424856",
        fontWeight: "bold",
        marginLeft: 30,
        cursor: 'pointer'
      }}
      onClick={()=> navigate('/colaborador')}
    >
      <img src={peoples} style={{ marginRight: 3 }} /> Colaboradores
    </div>
  </div>
  <div
    style={{
      paddingRight: 45,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 10
    }}
  >
    <i className="bi bi-bell" style={{ fontSize: 16 }} />
    <i className="bi bi-person-circle" style={{ fontSize: 28 }} />
  </div>
</div>












































</nav>
)
}

export default HomeLf;
