

import { useEffect, useState } from 'react';
import './Login.css';
import { getAuth, onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';
import { firebaseAuth } from '../utils/firebase-config';
import { useNavigate } from 'react-router-dom';
import logo from '../img/logo.png';
import sun from '../assets/version2/sun.png';
import lamp from '../assets/version2/lamp.png';

function Entrar() {







  if(window.location.href.slice(window.location.href.length - 1, window.location.href.length) != '/') {
    document.querySelector('.oisumida').style.display = 'none';
} else {

    document.querySelector('.oisumida').style.display = 'block';
    
}










  const [emailLL, setEmailLL] = useState()
  const [passwordDD, setPasswordDD] = useState();

  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  const [logadoM, setLogadoM] = useState(false);
  const [reseter, setReseter] = useState(false);
  const [emailRst, setEmailRst] = useState('');

  const queryParameters = new URLSearchParams(window.location.search)
  const toHome = queryParameters.get("tohome")





  async function resetEmail(params) {
    const auth = getAuth();
sendPasswordResetEmail(auth, emailRst)
  .then(() => {
    alert(`Email de Redefinição enviado para ${emailRst}`);
    navigate('/')
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    alert(error.message);
    // ..
  });
  }


async function irPara(params) {
 
  try {
    await signInWithEmailAndPassword(firebaseAuth, emailLL, passwordDD);
    navigate('/main');
  } catch (error) {
    setMsg(error.code);
  }

}
const auth = getAuth();
useEffect(()=> {

    if(toHome == 'logout'){
      onAuthStateChanged(auth, async (user) => {
        if (user) {
    
          const uid = user.uid;
    
    setLogadoM(false);
    
        } else {
          setLogadoM(true)
    
        }
      });
    } else {
   //   navigate('/');
    }
 



}, [])





  return (
    <>
   <div style={{ display: 'none'}}>


{
    1 == 1 ?
// logadoM ?

  <div class="publicar" style={{width: '100%'}}>
  <img src={logo} style={{height: '50px'}} />
{!reseter ?
  <> </>
  :
  <h1 class="titulo" style={{color: 'black'}}>Redefinir Senha</h1>
 
}
{
  !reseter ?
  <>
<input class="criar"

onChange={(e) => setEmailLL(e.target.value)}
value={emailLL}
style={{paddingLeft: '10px', width: '250px', borderColor: 'black', color: 'black'}} placeholder="Email"></input>
<input 

type='password'
onChange={(e) => setPasswordDD(e.target.value)}
value={passwordDD}
class="criar" style={{paddingLeft: '10px', width: '250px', borderColor: 'black', color: 'black'}} placeholder="Senha"></input>
<button 
onClick={()=> irPara()}
className='button' style={{backgroundColor: 'rgb(222,222,222)', padding: '10px 30px', borderRadius: '12px'}}>Logar</button>
</>
  : 
  <>
<input 

type='email'
onChange={(e) => setEmailRst(e.target.value)}
value={passwordDD}
class="criar" style={{paddingLeft: '10px', width: '250px', color: 'black', borderColor: 'black' }} placeholder="Email"></input>
<button 
onClick={()=> resetEmail()}
className='button' style={{backgroundColor: 'rgb(222,222,222)', padding: '10px 30px', borderRadius: '12px'}}>Enviar</button>
</>
}


     {
                  msg.length > 2 ? 
<a style={{color: 'red', fontSize: '15px', padding: '10px', margin: '10px', marginLeft: '0px', backgroundColor: '#999'}}>{msg.replace('auth/', '')}</a>          
                  : ''
              }
{
  !reseter &&
  <a
  onClick={()=> setReseter(true)}
  style={{textDecoration: 'underline', padding: '10px 0px', fontSize: '14px', color: '#555', cursor: 'pointer'}}>Esqueci a senha</a>

}
         
         
              </div>

: 
<div class="publicar">

<h1 class="titulo">Você já está logado!</h1>


<button 
onClick={()=> navigate('/')}
className='button'
style={{marginTop: '50px'}}
>Voltar</button>
     {
                  msg.length > 2 ? 
<a style={{color: 'red', fontSize: '15px', padding: '10px', margin: '10px', marginLeft: '0px', backgroundColor: '#999'}}>{msg.replace('auth/', '')}</a>          
                  : ''
              }
              
              </div>


}




</div>






































<div className="row" style={{display: 'flex', justifyContent:'center'}}>
  <div className="col-md-2 col-sm-12 flex-column align-items-end sumir">
 
  </div>
  <div
    className="col-md-4 col-sm-12 vocedevolta d-flex flex-column align-items-md-start align-items-sm-center"
    style={{ paddingTop: 120 }}
  >
    Que bom ter
    <br /> você de volta!
    <div className="seespaco">
      {" "}
      <br />
      Seu espaço para colaboração e controle de
      <br /> projetos 3D está te esperando. Vamos lá!
    </div>
    <img style={{ marginTop: 40, width: 50, height: 50 }} src={sun} />
  </div>
  <div
    className="col-md-4 col-sm-12 d-flex flex-column cardlogin align-items-sm-center align-items-md-center"
    style={{ paddingTop: 100 }}
  >
    <div
      className="quadradologin row row-cols-1"
      style={{ alignItems: "center", justifyContent: "center" }}
    >
      <div
        className="col-12 fonteLogin"
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: 30,
          marginTop: 30
        }}
      >
        Login
      </div>
      <div
        className="col-10"
        style={{
          display: "column",
          justifyContent: "center",
          marginTop: "-40px"
        }}
      >
        <div
          className="fontLoginInput"
          style={{
            display: "flex",
            flexFlow: "column",
            backgroundColor: "#F3F4F6",
            paddingLeft: 10,
            paddingRight: 140,
            paddingTop: 2,
            paddingBottom: 2,
            borderRadius: 6
          }}
        >
          Email{" "}
          <input
            className="inputLogin fontLoginInputPlace"
            style={{ border: "none", backgroundColor: "#F3F4F6" }}
            placeholder="seu.email@empresa.com"
            type="text"
            onChange={(e) => setEmailLL(e.target.value)}
          />{" "}
        </div>
        <div
          className="fontLoginInput"
          style={{
            display: "flex",
            flexFlow: "column",
            backgroundColor: "#F3F4F6",
            paddingLeft: 10,
            paddingTop: 2,
            paddingBottom: 2,
            borderRadius: 6,
            marginTop: 20
          }}
        >
          Senha{" "}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {" "}
            <input
              className="inputLogin fontLoginInputPlace"
              style={{ border: "none", backgroundColor: "#F3F4F6", width: 400 }}
              placeholder="Sua senha "
              type="password"
              onChange={(e) => setPasswordDD(e.target.value)}
            />
            <a>X</a>
          </div>{" "}
        </div>
      </div>
      <div
        className="col-10"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "-60px"
        }}
      >
        <div className="fontLoginFoget" style={{ marginLeft: 2 }}>
          {" "}
          <input type="checkbox" style={{ marginRight: 6 }} />
          Mantenha-me conectado
        </div>
        <a className="loginEsqueciSenha">Esqueceu a senha?</a>
      </div>
      <div className="col-10" style={{ marginTop: "-105px" }}>
        <button className="botaoLogin"
        onClick={()=> irPara()}
        >Login</button>
      </div>
    </div>
  </div>
  <div
    className="col-md-2 col-sm-12"
    style={{ paddingTop: 260, paddingLeft: 80 }}
  >
    <img src={lamp} />
  </div>
</div>
































</>
  );
}

export default Entrar;






